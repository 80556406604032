// На самом деле тут Return не нужен, но если писать либу - обязателен
export function useDebounce(func, timeoutMs) {
    let lastCall, lastCallTimer;
    return (args) => {
        const previousCall = lastCall;
        lastCall = Date.now();
        if (previousCall && lastCall - previousCall <= timeoutMs) {
            clearTimeout(lastCallTimer);
        }
        lastCallTimer = setTimeout(() => func(args), timeoutMs);
    };
}
