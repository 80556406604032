import { CategoryApi } from '../api/category';
import { Store } from '../services/store/Store';
import { searchNode, tree } from '../utils/categoryTree';
export class CategoryStore extends Store {
    constructor() {
        const initState = {
            list: [],
        };
        super(initState);
        this.refresh();
    }
    async refresh() {
        const res = await CategoryApi.getAll();
        const body = res.body;
        this.state.list = body;
        this.state.root = tree(body);
    }
    getById = (id) => {
        if (this.state.root) {
            return searchNode(this.state.root, id);
        }
        return undefined;
    };
    getList = () => this.state.list;
    getFirst = () => this.state.list[0]?.id;
    addActions() {
        this.addAction({
            name: 'CATEGORY_STORE_REFRESH',
            operation: async () => await this.refresh(),
        });
    }
}
export default new CategoryStore();
