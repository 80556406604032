import './dropdown.scss';
import { Component } from '../snail/component';
import { createElement, createComponent, createText } from '../snail/vdom/VirtualDOM';
import { Svg } from '../index';
import { TextInput } from '../index';
import searchIcon from '../../../assets/icons/search.svg';
export class Dropdown extends Component {
    state = {
        hidden: true,
    };
    switchHidden() {
        this.setState({
            hidden: !this.state.hidden,
        });
    }
    setParent(component) {
        // debugger;
        this.state.parent = component;
    }
    componentDidMount() {
        // document.body.addEventListener('click', this.clickOutsideEvent);
        // Navigate.addCallback(() => this.setState({hidden: false}));
    }
    remove = () => this.unmount();
    clickOutsideEvent = (e) => {
        e.preventDefault();
        const element = e.target;
        // debugger;
        this.domElement?.parentElement;
        element.parentElement?.previousElementSibling;
        // if (element === this.state.parent?.instance?.domElement || element.parentElement === this.state.parent?.instance?.domElement) {
        //     debugger;
        // }
        // (e.target as HTMLElement).parentElement;
    };
    componentWillUnmount() {
        // document.body.removeEventListener('click', this.clickOutsideEvent);
        // Navigate.addCallback(() => this.setState({hidden: false}));
    }
    render() {
        return createElement('div', {
            class: 'dropdown-container',
            style: 'top: 44px;',
            hidden: this.state.hidden,
        }, (this.props.search) ?
            createElement('div', { class: 'dropdown-search' }, createComponent(Svg, { content: searchIcon }), createComponent(TextInput, {})) : createText(''), (!this.state.hidden && this.children && this.children.length !== 0) ?
            createElement('div', { class: 'dropdown-content' }, ...this.children) : createText(''));
    }
}
