import './menu.scss';
import { Component } from '../../../components/baseComponents/snail/component';
import { createElement, createComponent } from '../../../components/baseComponents/snail/vdom/VirtualDOM';
import { TextLink } from '../../../components/baseComponents';
export class Menu extends Component {
    componentDidMount() {
        this.state = {
            selectedIndex: this.props.selectedIndex || 0,
        };
    }
    getTextLinks() {
        const links = [];
        const selectedIndex = this.state ? this.state.selectedIndex : this.props.selectedIndex;
        this.props.options.forEach((link, index) => {
            links.push(createComponent(TextLink, {
                ...link,
                variant: this.props.variant ?
                    (this.props.variant == 'sidebar') ?
                        'default' : 'underline'
                    : 'default',
                selected: selectedIndex === index,
                onclick: () => {
                    this.setState({
                        ...this.state,
                        selectedIndex: index,
                    });
                    link.onclick();
                },
            }));
        });
        return links;
    }
    render() {
        return createElement('div', {
            class: 'menu-' + (this.props.variant ? this.props.variant : 'sidebar'),
        }, ...this.getTextLinks());
    }
}
