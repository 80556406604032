import './PasswordField.scss';
import { Component } from '../snail/component';
import { createComponent, createElement } from '../snail/vdom/VirtualDOM';
import { BaseInput, Button } from '..';
export class PasswordField extends Component {
    state = {
        type: 'password',
        preventType: 'text',
    };
    setType(newType) {
        if (newType !== this.state.type) {
            this.setState({
                preventType: this.state.type,
                type: newType,
            });
        }
    }
    render() {
        const inputProps = {
            placeholder: 'Пароль',
            autocomplete: 'new-password',
            required: true,
            class: 'input-field',
            type: this.state.type,
            textType: 'regular',
            ...this.props,
        };
        return createElement('div', { class: 'password-field' }, createComponent(BaseInput, { ...inputProps }), createComponent(Button, {
            text: 'показать',
            variant: 'outlined',
            type: 'button',
            mousedown: (e) => {
                e.preventDefault();
                this.setType('text');
            },
            mouseup: (e) => {
                e.preventDefault();
                this.setType('password');
            },
            mouseout: (e) => {
                e.preventDefault();
                this.setType('password');
            },
        }));
    }
}
