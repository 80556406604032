import './tooltip.scss';
import { Component } from '../snail/component';
import { createComponent, createElement } from '../snail/vdom/VirtualDOM';
import { Text } from '..';
export class Tooltip extends Component {
    render() {
        return createElement('div', {
            class: 'tooltip',
        }, createElement('div', { class: 'tooltip-pointer' }), createElement('div', { class: 'tooltip-container' }, createComponent(Text, { text: this.props.text, variant: 'caption', className: 'tooltip-text' })));
    }
}
