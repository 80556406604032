import { Component } from '../../../../components/baseComponents/snail/component';
import { createComponent } from '../../../../components/baseComponents/snail/vdom/VirtualDOM';
import { ProfilePage } from '../../profilePage/profilePage';
import { CommentApi } from '../../../../shared/api/comment';
import { ResponseMessage, ResponseStatusChecker } from '../../../../shared/constants/response';
import { CommentCard } from '../../../../components/commentCard/commentCard';
export class SalerComments extends Component {
    state = {
        loading: true,
        error: '',
        contentBlocks: [],
        selectedPage: 0,
    };
    componentDidMount() {
        this.getBlocks(0);
    }
    getSelectedIndex() {
        return 0;
    }
    async getBlocks(selectedIndex) {
        let resp;
        try {
            resp = await CommentApi.getComments(history.state.salerId);
        }
        catch (err) {
            this.setState({
                ...this.state,
                loading: false,
                error: err.toString(),
                selectedPage: selectedIndex,
            });
        }
        if (!ResponseStatusChecker.IsSuccessfulRequest(resp)) {
            if (ResponseStatusChecker.IsBadFormatRequest(resp)) {
                this.setState({
                    ...this.state,
                    loading: false,
                    error: ResponseMessage.USER_MESSAGE,
                    selectedPage: selectedIndex,
                });
                return;
            }
            else if (ResponseStatusChecker.IsInternalServerError(resp)) {
                this.setState({
                    ...this.state,
                    loading: false,
                    error: ResponseMessage.SERVER_MESSAGE,
                    selectedPage: selectedIndex,
                });
                return;
            }
            else if (ResponseStatusChecker.IsUserError(resp)) {
                this.setState({
                    ...this.state,
                    loading: false,
                    error: resp.body.error,
                    selectedPage: selectedIndex,
                });
                return;
            }
        }
        this.setState({
            ...this.state,
            loading: false,
            error: '',
            contentBlocks: resp.body ? [...resp.body] : [],
            selectedPage: selectedIndex,
        });
    }
    createContainer() {
        const result = [];
        this.state.contentBlocks.forEach((block) => {
            result.push(createComponent(CommentCard, {
                ...block,
            }));
        });
        return result;
    }
    render() {
        return createComponent(ProfilePage, {
            title: 'Отзывы',
            cardVariant: 'comment',
            gridXRepeat: 1,
            options: [
                {
                    name: 'Отзывы',
                    link: 'comments',
                    emptyMessage: 'У данного продавца пока нет отзывов',
                    apiFunction: CommentApi.getComments,
                    apiParams: history.state.salerId,
                },
            ],
        });
    }
}
