import { Component } from '../../../components/baseComponents/snail/component';
import { createElement, createText } from '../../../components/baseComponents/snail/vdom/VirtualDOM';
import Navigate from './Navigate';
// у компонента Route может быть только 1 ребёнок
export class Route extends Component {
    get path() {
        if (!this.props) {
            throw new Error('');
        }
        return this.props.path;
    }
    render() {
        if (!this.children) {
            throw new Error('Route children are undefined');
        }
        if (this.children.length > 1) {
            throw new Error('Route have only 1 child');
        }
        if (this.props?.callback) {
            this.props.callback();
        }
        return this.children[0];
    }
}
export class Router extends Component {
    // связка Роутера и Навигатора
    componentDidMount() {
        if (!this.props.mini_router) {
            Navigate.addCallback(() => {
                this.applyComponentChanges();
            });
        }
    }
    componentWillUnmount() {
        /*if (!this.props.mini_router) {
            Navigate.removeCallback(() => { this.applyComponentChanges() });
        }*/
    }
    render() {
        if (!this.children) {
            throw new Error('children are undefined');
        }
        const route = (this.children).find((child) => {
            if (child.key !== 'Route') {
                throw new Error('Router child must be Route component');
            }
            const props = child.props;
            return props.path.exec(location.pathname + location.search);
        });
        if (route) {
            return route;
        }
        return createElement('div', {}, createText('Ошибка при роутинге'));
    }
}
