import { UserApi } from '../api/user';
import { Store } from '../services/store/Store';
export class FavouriteStore extends Store {
    constructor() {
        const initState = {
            list: [],
        };
        super(initState);
        this.refresh();
    }
    getById = (id) => {
        if (this.state.list) {
            return this.state.list.find((value) => value.id === id);
        }
        return undefined;
    };
    refresh = async () => {
        this.state.list = [];
        let res;
        try {
            res = await UserApi.getFavs();
        }
        catch (err) {
            console.error(err);
        }
        if (res.status === 2000 && res.body) {
            res.body.forEach((elem) => this.state.list.push(elem));
        }
    };
    addActions() {
        this.addAction({
            name: 'FAVOURITE_FULL',
            operation: ({ payload }) => this.state.list.push({ id: payload }),
        });
        this.addAction({
            name: 'FAVOURITE_ADD',
            operation: action => this.state.list.push({ id: action.payload }),
        });
        this.addAction({
            name: 'FAVOURITE_REMOVE',
            operation: action => this.state.list = this.state.list.filter((elem) => elem.id !== action.payload),
        });
    }
}
export default new FavouriteStore();
