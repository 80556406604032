import './settings.scss';
import { Component } from '../../../../components/baseComponents/snail/component';
import { createComponent, createElement } from '../../../../components/baseComponents/snail/vdom/VirtualDOM';
import { Button, FileInput, Text, TextInput } from '../../../../components/baseComponents/index';
import user, { UserStoreAction } from '../../../../shared/store/user';
import Dispatcher from '../../../../shared/services/store/Dispatcher';
import { UserApi } from '../../../../shared/api/user';
import { FileApi } from '../../../../shared/api/file';
import { ResponseStatusChecker } from '../../../../shared/constants/response';
export class ProfileSettings extends Component {
    emailField;
    phoneField;
    nameField;
    avatarField;
    state = {
        inputFields: {},
    };
    componentDidMount() {
        user.addStoreUpdater(this.listener);
        Dispatcher.dispatch({
            name: UserStoreAction.REFRESH,
        });
    }
    componentWillUnmount() {
        user.removeStoreUpdater(this.listener);
    }
    clear = () => {
        delete this.state.inputFields;
        if (this.emailField) {
            this.emailField.value = '';
            // delete this.state.inputFields?.email;
        }
        if (this.phoneField) {
            this.phoneField.value = '';
            // delete this.state.inputFields?.phone;
        }
        if (this.nameField) {
            this.nameField.value = '';
            // delete this.state.inputFields?.name;
        }
    };
    clearEvent = (e) => {
        e.preventDefault();
        this.clear();
    };
    listener = () => {
        const fields = user.getFields();
        if (fields) {
            this.setState({
                user: { ...fields },
            });
        }
    };
    async uploadAvatar() {
        if (this.state.inputFields?.forUpload) {
            let res;
            try {
                res = await FileApi.images(this.state.inputFields.forUpload);
            }
            catch (err) {
                console.error(err);
            }
            if (!ResponseStatusChecker.IsSuccessfulRequest(res)) {
                console.error(new Error(res.body.error));
                return;
            }
            this.state.inputFields.avatar = res.body.urls[0];
        }
    }
    formSubmit = async (e) => {
        e.preventDefault();
        await this.uploadAvatar();
        let res;
        try {
            res = await UserApi.patchProfile({ ...this.state.inputFields });
        }
        catch (err) {
            console.error(err);
            return;
        }
        if (!ResponseStatusChecker.IsSuccessfulRequest(res)) {
            console.error(new Error(res.body.error));
            return;
        }
        this.clear();
        Dispatcher.dispatch({
            name: UserStoreAction.UPDATE,
            payload: res.body,
        });
    };
    setAvatar = (e) => {
        const input = e.currentTarget;
        if (!this.avatarField) {
            this.avatarField = input;
        }
        if (this.state.inputFields && input.files) {
            this.state.inputFields.forUpload = input.files[0];
        }
    };
    setName = (e) => {
        if (!this.nameField) {
            this.nameField = e.currentTarget;
        }
        if (this.state.inputFields) {
            this.state.inputFields.name = e.currentTarget.value;
        }
    };
    setEmail = (e) => {
        if (!this.emailField) {
            this.emailField = e.currentTarget;
        }
        if (this.state.inputFields) {
            this.state.inputFields.email = e.currentTarget.value;
        }
    };
    setPhone = (e) => {
        if (!this.phoneField) {
            this.phoneField = e.currentTarget;
        }
        if (this.state.inputFields) {
            this.state.inputFields.phone = e.currentTarget.value;
        }
    };
    render() {
        this;
        return createElement('settings', {
            class: 'settings',
        }, createComponent(Text, {
            tag: 'div',
            variant: 'subheader',
            text: 'Настройки',
            style: 'padding-bottom: 32px;',
        }), createElement('form', {
            class: 'settings-content',
            onsubmit: this.formSubmit,
        }, createComponent(Text, {
            text: 'Аватарка',
        }), createComponent(FileInput, {
            accept: '.png, .jpg, .jpeg',
            multiple: false,
            oninput: this.setAvatar,
        }), createComponent(Text, {
            text: 'Имя',
        }), createComponent(TextInput, {
            placeholder: this.state?.user?.name ? this.state?.user?.name : '',
            oninput: this.setName,
        }), createComponent(Text, {
            text: 'Телефон',
        }), createComponent(TextInput, {
            placeholder: this.state?.user?.phone ? this.state?.user.phone : '',
            oninput: this.setPhone,
        }), createComponent(Text, {
            text: 'Почта',
        }), createComponent(TextInput, {
            placeholder: this.state?.user?.email ? this.state?.user?.email : '',
            oninput: this.setPhone,
        }), createComponent(Button, {
            text: 'Сохранить',
            variant: 'primary',
        }), createComponent(Button, {
            text: 'Отменить',
            variant: 'neutral',
            subvariant: 'primary',
            onclick: this.clearEvent,
        })));
    }
}
