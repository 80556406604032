export function useRetry(func, retryCount) {
    let currentRetryCount;
    return async (...args) => {
        try {
            return await func(...args);
        }
        catch {
            currentRetryCount++;
            if (currentRetryCount > retryCount) {
                return;
            }
            return;
        }
    };
}
