import './Input.scss';
import { Component } from '../snail/component';
import { createComponent, createElement } from '../snail/vdom/VirtualDOM';
import { ErrorMessageBox, Text } from '../index';
import { Validate } from '../../../shared/utils/validation';
import { getTextClass } from '../text/Text';
export class BaseInput extends Component {
    render() {
        const { textType, ...otherProps } = this.props;
        return createElement('input', {
            ...otherProps,
            class: getTextClass(textType) + (this.props.class ? ' ' + this.props.class : ''),
        });
    }
}
export class TextInput extends Component {
    render() {
        const { textType, ...otherProps } = this.props;
        return createElement('input', {
            type: 'text',
            ...otherProps,
            class: getTextClass(textType) + (this.props.class ? ' ' + this.props.class : ''),
        });
    }
}
export class NumberInput extends Component {
    render() {
        const { textType, min, max, ...otherProps } = this.props;
        return createElement('input', {
            type: 'number',
            min: (min !== undefined) ? min.toString() : '0',
            max: (max !== undefined) ? max.toString() : '',
            ...otherProps,
            class: getTextClass(textType) + (this.props.class ? ' ' + this.props.class : ''),
        });
    }
}
export class BooleanInput extends Component {
    render() {
        return createElement('input', {
            type: 'checkbox',
            ...this.props,
        });
    }
}
export class Password extends Component {
    render() {
        const { textType, ...otherProps } = this.props;
        return createElement('input', {
            type: 'password',
            ...otherProps,
            class: getTextClass(textType) + (this.props.class ? ' ' + this.props.class : ''),
        });
    }
}
export class FileInput extends Component {
    state = {
        files: [],
    };
    onInputEvent = (e) => {
        const input = e.currentTarget;
        if (input.files) {
            const files = Array.from(input.files);
            if (input.accept) {
                const validation = Validate.allowedFormats(input.accept, files);
                if (validation) {
                    this.setState({
                        error: `Недопустимый формат: ${validation}`,
                    });
                    return;
                }
            }
            this.setState({
                files: files,
            });
            if (this.props?.oninput) {
                this.props.oninput(e);
            }
        }
    };
    render() {
        let { text, oninput, ...other } = this.props; // eslint-disable-line prefer-const, @typescript-eslint/no-unused-vars
        let error = [];
        const fileNames = [];
        if (text === undefined) {
            text = 'Выбрать файлы';
        }
        if (this.state.error) {
            error = [
                createComponent(ErrorMessageBox, {
                    text: this.state.error,
                }),
            ];
        }
        if (this.state.files && !this.state.error) {
            this.state.files.forEach((file) => fileNames.push(createComponent(Text, { text: file.name })));
        }
        return createElement('div', { class: 'input-base' }, createElement('div', {
            class: 'input-container',
        }, createElement('input', {
            class: 'input-container-file',
            type: 'file',
            oninput: this.onInputEvent,
            ...other,
        }), createElement('label', { class: 'input-container-label' }, createComponent(Text, { text }))), ...fileNames, ...error);
    }
}
