import { checkTagLikeSvgTag, getXMLNS } from './SVGRender';
export const createText = (value, key = '') => {
    return ({
        kind: 'text',
        value: value ? value.toString() : '',
        key: key,
    });
};
// парсер необходим для svg-элементов
const parser = new DOMParser();
export const createElement = (tag, props, ...children) => {
    return ({
        kind: 'element',
        tag: tag,
        props: props,
        children: [...children],
        key: tag,
    });
};
export const createComponent = (component, props, ...children) => {
    return ({
        kind: 'component',
        props: props,
        children: children,
        component: component,
        key: component.name,
    });
};
export const renderVDomNode = (rootNode) => {
    if (!rootNode || rootNode.kind == 'text') {
        if (!rootNode) {
            return document.createTextNode('');
        }
        return document.createTextNode(rootNode.value.toString());
    }
    if (rootNode.kind == 'element') {
        if (rootNode.tag == 'svg-element') {
            if (!rootNode.props) {
                throw new Error('Svg element must have props');
            }
            const svgElement = parser.parseFromString(rootNode.props['svgcontent'].toString(), 'image/svg+xml');
            return svgElement.documentElement;
        }
        const element = (!checkTagLikeSvgTag(rootNode.tag)) ?
            document.createElement(rootNode.tag)
            : document.createElementNS(getXMLNS(rootNode.props), rootNode.tag);
        Object.keys(rootNode.props || {}).forEach((prop) => {
            if (rootNode.props) {
                if (prop == 'class') {
                    rootNode.props[prop].toString().trim().split(' ').forEach((elementClass) => {
                        if (elementClass !== '') {
                            element.classList.add(elementClass);
                        }
                    });
                }
                else if (rootNode.props[prop] instanceof Function && !prop.startsWith('on')) {
                    let eventName = '';
                    let eventFunc = () => { };
                    try {
                        eventName = prop; // eslint-disable-line no-undef
                        eventFunc = rootNode.props[prop];
                    }
                    catch {
                        throw new Error(prop + ' is not an event name');
                    }
                    element.addEventListener(eventName, eventFunc);
                }
                else {
                    if (checkTagLikeSvgTag(rootNode.tag)) {
                        element.setAttribute(prop, rootNode.props[prop].toString());
                    }
                    else {
                        element[prop] = rootNode.props[prop];
                    }
                }
            }
        });
        (rootNode.children || []).forEach((child) => {
            element.appendChild(renderVDomNode(child));
        });
        return element;
    }
    if (rootNode.instance) {
        const element = renderVDomNode(rootNode.instance.render());
        rootNode.instance.notifyMounted(element);
        return element;
    }
    rootNode.instance = new rootNode.component();
    rootNode.instance.setChildren(rootNode.children);
    const element = renderVDomNode(rootNode.instance.initProps(rootNode.props));
    rootNode.instance.notifyMounted(element);
    return element;
};
export const renderToElementDyId = (elementId, node) => {
    const element = document.getElementById(elementId);
    if (!element) {
        throw new Error('Element with this id is undefined');
    }
    const parentElement = element.parentElement;
    if (!parentElement) {
        throw new Error('Parent elment is undefined');
    }
    element.replaceWith(renderVDomNode(node));
    return parentElement.children[0];
};
