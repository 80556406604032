import { Component } from './components/baseComponents/snail/component';
import { createComponent, createElement } from './components/baseComponents/snail/vdom/VirtualDOM';
import { Router, Route } from './shared/services/router/Routing';
import { SigninPage } from './pages/signin/SigninPage';
import { SignupPage } from './pages/signup/SignupPage';
import { MainPage } from './pages/main/Main';
import { CartPage } from './pages/cart/CartPage';
import { login } from './shared/store/commonActions/auth';
import { Loader } from './components/loader/Loader';
import { Product } from './pages/product/product';
import { UserProfile } from './pages/profile/user/user';
import { getOrders } from './shared/store/commonActions/getOrders';
import { MessageBox } from './components/baseComponents';
import { SalerProfile } from './pages/profile/saler/saler';
export class App extends Component {
    state = {
        loading: true,
    };
    async loader() {
        await login();
        await getOrders();
        this.setState({
            loading: false,
        });
    }
    componentDidMount() {
        this.loader();
    }
    render() {
        return createElement('div', { id: 'root' }, createComponent(MessageBox, {}), (this.state?.loading) ?
            createComponent(Loader, {})
            :
                createComponent(Router, {}, createComponent(Route, { path: new RegExp('^/$') }, createComponent(MainPage, {})), createComponent(Route, { path: new RegExp('^/signin$') }, createComponent(SigninPage, {})), createComponent(Route, { path: new RegExp('^/signup$') }, createComponent(SignupPage, {})), createComponent(Route, { path: new RegExp('^/product.*') }, createComponent(Product, {})), createComponent(Route, { path: new RegExp('^/profile.*') }, createComponent(UserProfile, {})), createComponent(Route, { path: new RegExp('^/saler.*') }, createComponent(SalerProfile, {})), createComponent(Route, { path: new RegExp('^/cart$') }, createComponent(CartPage, {}))));
    }
}
