import { CityApi } from '../api/city';
import { Store } from '../services/store/Store';
export class CityStore extends Store {
    constructor() {
        const initState = {
            list: [],
        };
        super(initState);
        this.refresh();
    }
    async refresh() {
        const res = await CityApi.getAll();
        this.state.list = res.body;
    }
    // init: async function() {
    //     const res = await CityApi.getAll();
    //     this.list = res.body;
    // },
    getById = (id) => this.state.list.find((value) => value.id === id);
    getList = () => this.state.list;
    getFirst = () => this.state.list[0]?.id;
    addActions() {
        this.addAction({
            name: 'CITY_STORE_REFRESH',
            operation: async () => await this.refresh(),
        });
    }
}
export default new CityStore();
