import Dispatcher from './Dispatcher';
export class Store {
    initState;
    state;
    listeners = [];
    actions = [];
    constructor(initState) {
        this.initState = structuredClone(initState);
        this.state = structuredClone(initState);
        this.addActions();
        Dispatcher.register((action) => {
            this.actions.find((innerAction) => innerAction.name == action.name)?.operation(action);
            this.emitChange();
        });
    }
    init() {
        this.state = structuredClone(this.initState);
    }
    addAction = (action) => {
        this.actions.push(action);
    };
    addStoreUpdater(listener) {
        this.listeners.push(listener);
    }
    removeStoreUpdater(listener) {
        this.listeners = this.listeners.filter((element) => {
            return element !== listener;
        });
    }
    emitChange() {
        this.listeners.forEach((listener) => {
            listener();
        });
    }
}
