import './feed.scss';
import { Component } from '../baseComponents/snail/component';
import { createComponent, createElement } from '../baseComponents/snail/vdom/VirtualDOM';
import { Loader } from '../loader/Loader';
import { Text } from '../baseComponents/index';
import { ProductApi } from '../../shared/api/product';
import { ResponseStatusChecker } from '../../shared/constants/response';
import Navigate from '../../shared/services/router/Navigate';
import { useDebounce } from '../baseComponents/snail/use/debounce';
import { Card } from '../card/Card';
export class Feed extends Component {
    state = {
        products: [],
    };
    endOfProducts = false;
    constructor() {
        super();
        Navigate.addCallback(this.updateEvent);
    }
    async addLoading() {
        let res;
        try {
            res = await ProductApi.feed(this.state.products.length);
        }
        catch {
            return;
        }
        if (!ResponseStatusChecker.IsSuccessfulRequest(res)) {
            return;
        }
        if (!res.body) {
            this.endOfProducts = true;
            return;
        }
        if (res.body.length < 1) {
            this.endOfProducts = true;
            return;
        }
        this.setState({
            products: [...this.state.products, ...res.body],
        });
    }
    scrollEndEvent = () => {
        if (this.endOfProducts) {
            return;
        }
        if ((window.innerHeight + window.scrollY) >= document.body.scrollHeight) {
            document.body.clientHeight;
            this.setState({
                products: this.state.products,
            });
            this.addLoading();
        }
    };
    componentDidMount() {
        this.updateEvent();
        window.addEventListener('scroll', this.scrollEndEvent);
    }
    componentWillUnmount() {
        window.removeEventListener('scroll', this.scrollEndEvent);
        Navigate.removeCallback(this.updateEvent);
    }
    updateEvent = () => {
        if (history.state?.products) {
            this.setState({
                products: history.state.products,
            });
        }
        else {
            this.getProductList();
        }
    };
    getProductList = useDebounce(async () => {
        let resp;
        try {
            resp = await ProductApi.feed();
        }
        catch (err) {
            console.error(err);
            return;
        }
        if (!ResponseStatusChecker.IsSuccessfulRequest(resp)) {
            if (ResponseStatusChecker.IsBadFormatRequest(resp)) {
                return;
            }
            else if (ResponseStatusChecker.IsInternalServerError(resp)) {
                return;
            }
            else if (ResponseStatusChecker.IsUserError(resp)) {
                return;
            }
        }
        this.setState({
            products: resp.body ? resp.body : [],
        });
    }, 500);
    createCards = () => {
        if (this.state.products.length === 0) {
            return [createComponent(Loader, {})];
        }
        else {
            return this.state.products.map((cardProps) => createComponent(Card, {
                ...cardProps,
            }));
        }
    };
    render() {
        return createElement('div', {
            class: 'feed',
        }, createComponent(Text, {
            text: 'Все объявления',
            variant: 'subheader',
        }), createElement('div', {
            class: 'feed-content',
        }, ...this.createCards()));
    }
}
