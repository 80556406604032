import './Caption.scss';
import { Component } from '../baseComponents/snail/component';
import { createComponent } from '../baseComponents/snail/vdom/VirtualDOM';
import { Text } from '../baseComponents/index';
export class Caption extends Component {
    render() {
        return createComponent(Text, {
            variant: 'caption',
            text: this.props.text,
            className: 'caption-message',
        });
    }
}
