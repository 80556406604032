import './Text.scss';
import { Component } from '../snail/component';
import { createElement, createText } from '../snail/vdom/VirtualDOM';
export const TextPrefix = 'text-';
export const getTextClass = (type) => {
    return TextPrefix + (type || 'regular');
};
export class Text extends Component {
    priceSplit(price) {
        const str = price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ') + ' ₽';
        return str;
    }
    render() {
        const { variant, tag, text, className, type, ...textProps } = this.props;
        let newText = text;
        if (type === 'price' && newText !== undefined) {
            newText = this.priceSplit(newText);
        }
        return createElement(tag || 'span', {
            ...textProps,
            class: (className ? className + ' ' : '') + getTextClass(variant),
        }, createText(newText));
    }
}
