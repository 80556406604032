import { Store } from '../services/store/Store';
import { getOrders } from './commonActions/getOrders';
export var CartStoreAction;
(function (CartStoreAction) {
    CartStoreAction["ADD_GOOD"] = "ADD_GOOD";
    CartStoreAction["DELETE_GOOD"] = "DELETE_GOO";
    CartStoreAction["ADD_SALER"] = "ADD_SALER";
    CartStoreAction["FULL_CART"] = "FULL_CART";
    CartStoreAction["UPDATE_ORDER_COUNT"] = "UPDATE_ORDER_COUNT";
    CartStoreAction["BUY_ALL"] = "BUY_ALL";
    CartStoreAction["CLEAR_CART"] = "CLEAR_CART";
    CartStoreAction["REFRESH"] = "CART_STORE_REFRESH";
})(CartStoreAction || (CartStoreAction = {}));
const initState = {
    goods: [],
    saler: {
        id: 0,
        name: '',
        email: '',
        avatar: '',
    },
    loading: false,
};
class CartStore extends Store {
    addActions() {
        this.addAction({
            name: CartStoreAction.ADD_GOOD,
            operation: ({ payload }) => this.addInCart(payload.good, payload.saler),
        });
        this.addAction({
            name: CartStoreAction.DELETE_GOOD,
            operation: ({ payload }) => this.deleteFromCart(payload),
        });
        this.addAction({
            name: CartStoreAction.ADD_SALER,
            operation: ({ payload }) => this.updateUser(payload),
        });
        this.addAction({
            name: CartStoreAction.FULL_CART,
            operation: ({ payload }) => this.fullCart(payload.goods, payload.saler),
        });
        this.addAction({
            name: CartStoreAction.UPDATE_ORDER_COUNT,
            operation: ({ payload }) => this.updateOrderCount(payload.orderId, payload.count),
        });
        this.addAction({
            name: CartStoreAction.BUY_ALL,
            operation: () => this.clear(),
        });
        this.addAction({
            name: CartStoreAction.CLEAR_CART,
            operation: () => this.clear(),
        });
        this.addAction({
            name: CartStoreAction.REFRESH,
            operation: async () => await this.refresh(),
        });
    }
    clearSaler() {
        this.state.saler = { ...initState.saler };
    }
    clear() {
        this.state = structuredClone(initState);
        this.state.goods = [];
        this.state.loading = false;
    }
    init() {
        this.clear();
    }
    hasUser() {
        return this.state.saler.id !== 0;
    }
    sameUser(userId) {
        return !this.hasUser() || userId === this.state.saler.id;
    }
    updateUser(saler) {
        this.state.saler = {
            ...this.state.saler,
            ...saler,
        };
        this.state.loading = false;
    }
    hasProduct(productId) {
        const index = this.state.goods.map(elem => elem.product_id).indexOf(productId);
        return index !== -1;
    }
    fullCart(goods, saler) {
        this.state.goods = [...goods];
        this.updateUser(saler);
        this.state.loading = false;
        return true;
    }
    addInCart(good, saler) {
        if (this.sameUser(saler.id)) {
            this.state.goods.push(good);
            if (!this.hasUser()) {
                this.updateUser(saler);
            }
            this.state.loading = false;
            return true;
        }
        this.state.loading = false;
        return false;
    }
    updateOrderCount(orderId, count) {
        const index = this.state.goods.map(elem => elem.id).indexOf(orderId);
        if (index !== -1) {
            this.state.goods[index].count = count;
        }
        this.state.loading = false;
    }
    deleteFromCart(orderId) {
        const index = this.state.goods.map(elem => elem.id).indexOf(orderId);
        if (index !== -1) {
            this.state.goods.splice(index, 1);
            if (this.getCount() === 0) {
                this.clearSaler();
            }
        }
        else {
            console.error(new Error('Error when deleting from cart'));
        }
        this.state.loading = false;
    }
    getCount() {
        let result = 0;
        this.state.goods.forEach((elem) => {
            result += elem.count;
        });
        return result;
    }
    getPrice() {
        let result = 0;
        this.state.goods.forEach((elem) => {
            result += elem.price * elem.count;
        });
        return result;
    }
    async refresh() {
        await getOrders();
        this.state.loading = false;
    }
    getGoods() {
        return [...this.state.goods];
    }
    getGood(orderId) {
        const index = this.state.goods.map((element) => element.id).indexOf(orderId);
        if (index !== -1) {
            return this.state.goods[index];
        }
        return this.state.goods[0];
    }
    getSaler() {
        return { ...this.state.saler };
    }
}
export default new CartStore(initState);
