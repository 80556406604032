var SvgTags;
(function (SvgTags) {
    SvgTags["SVG"] = "svg";
    SvgTags["POLYLINE"] = "polyline";
    SvgTags["PATH"] = "path";
})(SvgTags || (SvgTags = {}));
const basicXMLNS = 'http://www.w3.org/2000/svg';
export const checkTagLikeSvgTag = (tag) => {
    return Object.values(SvgTags).includes(tag);
};
export const getXMLNS = (nodeProps) => {
    if (!nodeProps) {
        return basicXMLNS;
    }
    if (!nodeProps['xmlns']) {
        return basicXMLNS;
    }
    return nodeProps['xmlns'].toString();
};
