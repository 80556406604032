export var UserRoutes;
(function (UserRoutes) {
    UserRoutes["GET_LIST_OF_USER"] = "product/get_list_of_saler";
    UserRoutes["GET_LIST_OF_SALER"] = "product/get_list_of_another_saler";
    UserRoutes["PROFILE_GET"] = "profile/get";
    UserRoutes["PROFILE_PUT"] = "profile/update";
    UserRoutes["PROFILE_PATCH"] = "profile/update";
    UserRoutes["PROFILE_FAVS"] = "profile/favourites";
    UserRoutes["ADD_TO_FAV"] = "product/add-to-fav";
    UserRoutes["REMOVE_FROM_FAV"] = "product/remove-from-fav";
    UserRoutes["ORDERS_GET"] = "profile/orders";
})(UserRoutes || (UserRoutes = {}));
export var AuthRoutes;
(function (AuthRoutes) {
    AuthRoutes["SIGNUP"] = "signup";
    AuthRoutes["SIGNIN"] = "signin";
})(AuthRoutes || (AuthRoutes = {}));
export var CategoryRoutes;
(function (CategoryRoutes) {
    CategoryRoutes["GET"] = "category/get_full";
})(CategoryRoutes || (CategoryRoutes = {}));
export var CityRoutes;
(function (CityRoutes) {
    CityRoutes["GET"] = "city/get_full";
})(CityRoutes || (CityRoutes = {}));
export var FilesRoutes;
(function (FilesRoutes) {
    FilesRoutes["IMAGES"] = "img/upload";
})(FilesRoutes || (FilesRoutes = {}));
export var OrderRoutes;
(function (OrderRoutes) {
    OrderRoutes["ADD"] = "order/add";
    OrderRoutes["BUY_FULL_BASKET"] = "order/buy_full_basket";
    OrderRoutes["GET_BASKET"] = "order/get_basket";
    OrderRoutes["UPDATE_COUNT"] = "order/update_count";
    OrderRoutes["UPDATE_STATUS"] = "order/update_status";
    OrderRoutes["SOLD"] = "order/sold";
    OrderRoutes["STATUS_NOT_IN_BASKET"] = "order/get_not_in_basket";
    OrderRoutes["DELETE"] = "order/delete";
})(OrderRoutes || (OrderRoutes = {}));
export var CommentRoutes;
(function (CommentRoutes) {
    CommentRoutes["ADD"] = "comment/add";
    CommentRoutes["DELETE"] = "comment/delete";
    CommentRoutes["GET_LIST"] = "comment/get_list";
    CommentRoutes["UPDATE"] = "comment/update";
})(CommentRoutes || (CommentRoutes = {}));
export var ProductRoutes;
(function (ProductRoutes) {
    ProductRoutes["LIST"] = "product/get_list";
    ProductRoutes["GET"] = "product/get";
    ProductRoutes["POST"] = "product/add";
    ProductRoutes["DELETE"] = "product/delete";
    ProductRoutes["PATCH"] = "product/update";
    ProductRoutes["PUT"] = "product/update";
    ProductRoutes["ACTIVATE"] = "product/activate";
    ProductRoutes["DEACTIVATE"] = "product/close";
    ProductRoutes["SEARCH"] = "product/search";
    ProductRoutes["SEARCH_FEED"] = "product/get_search_feed";
})(ProductRoutes || (ProductRoutes = {}));
export var PremiumRoutes;
(function (PremiumRoutes) {
    PremiumRoutes["ADD"] = "premium/add";
    PremiumRoutes["GET_STATUS"] = "premium/check";
})(PremiumRoutes || (PremiumRoutes = {}));
