import { PremiumRoutes } from '../constants/api';
import { Ajax } from '../services/ajax';
export class PremiumApi {
    static async add(id, period, signal) {
        return Ajax.getInstance().patch({
            url: PremiumRoutes.ADD,
            params: {
                'product_id': id,
                'period': period,
            },
            credentials: 'include',
            signal: signal,
        });
    }
    static async getStatus(id) {
        return Ajax.getInstance().get({
            url: PremiumRoutes.GET_STATUS,
            params: {
                'product_id': id,
            },
            credentials: 'include',
        });
    }
}
