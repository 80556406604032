import { applyChanges, getDifference } from './vdom/Difference';
export class Component {
    props;
    state;
    children = [];
    node;
    _domElement;
    constructor(props = {}) {
        this.props = props;
    }
    applyComponentChanges() {
        if (!this._domElement) {
            // throw new Error('domelement is undefined');
            return;
        }
        applyChanges(this._domElement, this.getComponentDifference());
    }
    get domElement() {
        // return this._domElement?.cloneNode();
        return this._domElement;
    }
    setState(state) {
        if (this._domElement) {
            this.state = { ...this.state, ...state };
            this.applyComponentChanges();
            // throw new Error('domelement is undefined');
        }
    }
    setProps(props) {
        if (!this._domElement) {
            return null;
        }
        this.state = this.componentWillRecieveProps(props, this.state);
        this.props = props;
        return this.getComponentDifference();
    }
    setChildren(children) {
        this.children = [...children];
        this.applyComponentChanges();
    }
    initProps(props) {
        this.props = props;
        this.node = this.render();
        return this.node;
    }
    notifyMounted(element) {
        this._domElement = element;
        // необходимо для асинхронного выполнения
        setTimeout(() => {
            this.componentDidMount();
        });
    }
    unmount() {
        this.componentWillUnmount();
        // this._domElement?.remove();
        this._domElement = undefined;
    }
    componentDidMount() { }
    componentDidUpdate() { }
    componentWillUnmount() { }
    // эта функция определят влияние параметров на состояние компонента
    componentWillRecieveProps(props, state) {
        return state;
    }
    getComponentDifference() {
        if (!this.node) {
            this.node = this.initProps(this.props);
        }
        const newNode = this.render();
        const difference = getDifference(this.node, newNode);
        if (difference.kind == 'replace') {
            // передаём стрелочную функцию для сохранения контекста
            // здесь не вызывается notifyMounted, так как он учатсвует в жизненном цикле компонента
            difference.callback = (element) => {
                this._domElement = element;
            };
        }
        this.node = newNode;
        // необходимо для асинхронного выполнения
        this.componentDidUpdate();
        /*setTimeout(() => {
            this.componentDidUpdate();
        });*/
        return difference;
    }
}
