import './textArea.scss';
import { Component } from '../snail/component';
import { createElement } from '../snail/vdom/VirtualDOM';
export class TextArea extends Component {
    render() {
        return createElement('textarea', {
            ...this.props,
            class: 'text-regular' + ((this.props.className && this.props.className !== '') ? ' ' + this.props.className : ''),
        });
    }
}
