import './image.scss';
import { Component } from '../snail/component';
import { createElement } from '../snail/vdom/VirtualDOM';
import { getResourceUrl } from '../../../shared/utils/getResource';
export class Image extends Component {
    render() {
        if (this.props.src) {
            this.props.src = getResourceUrl(this.props.src);
        }
        const { width, height, src, style, variant, ...etc } = this.props;
        if (src) {
            return createElement('img', {
                style: `width: ${width}; height: ${height}; ${style}`,
                src: src,
                class: 'img-avatar',
                ...etc,
            });
        }
        if (variant === 'avatar') {
            return createElement('img', {
                style: `width: ${width}; height: ${height}; ${style}`,
                src: '/assets/images/avatar-placeholder.png',
                ...etc,
            });
        }
        return createElement('img', {
            style: `width: ${width}; height: ${height}; ${style}`,
            ...etc,
        });
    }
}
