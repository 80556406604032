import './cardStyles/card.scss';
import { Component } from '../baseComponents/snail/component';
import { createComponent, createElement, createText } from '../baseComponents/snail/vdom/VirtualDOM';
import { Text, Button, Image, Select } from '../baseComponents/index';
import Navigate from '../../shared/services/router/Navigate';
import { UserApi } from '../../shared/api/user';
import { ProductApi } from '../../shared/api/product';
import { PremiumApi } from '../../shared/api/premium';
import { ResponseStatusChecker } from '../../shared/constants/response';
import { Modal } from '../modal/modal';
import { PremiumPeriods, PremuimStatus, premiumPeriodsList } from '../../shared/models/premium';
import delivery from '../../assets/icons/badges/delivery.svg';
import safeDeal from '../../assets/icons/badges/safe_deal.svg';
import { Badge } from './badge/Badge';
import { AlertMessage } from '../alertMessage/alertMessage';
import Dispatcher from '../../shared/services/store/Dispatcher';
import MessageStore, { MessageStoreAction } from '../../shared/store/message';
import { useRetry } from '../baseComponents/snail/use/shortPull';
import { Loader } from '../loader/Loader';
var MouseButtons;
(function (MouseButtons) {
    MouseButtons[MouseButtons["LEFT"] = 0] = "LEFT";
    MouseButtons[MouseButtons["WHEEL"] = 1] = "WHEEL";
    MouseButtons[MouseButtons["RIGHT"] = 2] = "RIGHT";
})(MouseButtons || (MouseButtons = {}));
export class Card extends Component {
    state = {
        isActive: false,
        paymentProcess: false,
    };
    componentDidMount() {
        this.setState({
            isActive: this.props?.is_active || false,
        });
        if (this.props.variant === 'profile') {
            this.getStatus();
        }
    }
    navigateToProduct = (e) => {
        switch (e.button) {
            case MouseButtons.LEFT:
                if (e.ctrlKey) {
                    window.open(`/product?id=${this.props?.id}`, '_blank');
                    return;
                }
                Navigate.navigateTo(`/product?id=${this.props?.id}`, { productId: this.props?.id });
                break;
            case MouseButtons.WHEEL:
                window.open(`/product?id=${this.props?.id}`, '_blank');
                break;
        }
    };
    thisHaveBadges = () => this.props.delivery || this.props.safe_deal || this.props.city;
    renderBadges(badgeClass) {
        const badges = [];
        if (this.props.delivery) {
            badges.push(createComponent(Badge, { class: badgeClass, svgIcon: delivery, tooltip: 'Возможна доставка' }));
        }
        if (this.props.safe_deal) {
            badges.push(createComponent(Badge, { class: badgeClass, svgIcon: safeDeal, tooltip: 'Безопасная сделка' }));
        }
        if (this.props.city) {
            badges.push(createComponent(Badge, { class: badgeClass, text: this.props.city }));
        }
        return badges;
    }
    renderBase() {
        return createElement('button', {
            class: `card-base${(this.props.premium ? '--premium' : '')}`,
            onclick: this.navigateToProduct,
        }, createElement('badges', { class: 'badges-base' }, ...this.renderBadges('badge-base')), createElement('div', { class: 'body-base' }, (this.props.images) ?
            createComponent(Image, {
                class: 'image-base',
                src: this.props.images[0].url,
            })
            :
                createElement('div', { class: 'image-base' }), createElement('div', { class: 'info-base' }, createComponent(Text, { text: this.props.price, type: 'price' }), createComponent(Text, { text: this.props.title, className: 'title-base' }))));
    }
    renderActiveButton() {
        const cp = this; // eslint-disable-line
        const changeActiveStatus = async (e) => {
            e.stopPropagation();
            let res;
            try {
                res = await ProductApi.changeActive(this.props.id, !this.state.isActive);
            }
            catch (err) {
                console.error(err);
            }
            if (!ResponseStatusChecker.IsRedirectResponse(res)) {
                return;
            }
            cp.setState({
                isActive: !this.state.isActive,
            });
            if (this.props.activeCallBack) {
                this.props.activeCallBack(this.props.id);
            }
        };
        return createComponent(Button, {
            variant: 'primary',
            text: (this.state.isActive) ? 'Деактивировать' : 'Активировать',
            style: 'width: 100%;',
            onclick: changeActiveStatus,
        });
    }
    // premuimWindow: Window | undefined = undefined;
    getStatus = async () => {
        let res;
        if (this.props.premium) {
            return;
        }
        try {
            res = await PremiumApi.getStatus(this.props.id);
        }
        catch (err) {
            console.error(err);
            return;
        }
        if (!ResponseStatusChecker.IsSuccessfulRequest(res)) {
            return;
        }
        const body = res.body;
        if (body.premium_status == (PremuimStatus.PENDING || PremuimStatus.WAITING_FOR_CAPTURE)) {
            this.setState({
                paymentProcess: true,
            });
            setTimeout(() => this.checkStatus(this.props.id), 29000);
            return;
        }
    };
    checkStatus = async (id) => {
        const sleepTimeout = 29 * 1000;
        let res;
        try {
            res = await PremiumApi.getStatus(id);
        }
        catch (err) {
            setTimeout(() => this.checkStatus(this.props.id), sleepTimeout);
            return;
        }
        if (!ResponseStatusChecker.IsSuccessfulRequest(res)) {
            setTimeout(() => this.checkStatus(this.props.id), sleepTimeout);
            return;
        }
        const body = res.body;
        switch (body.premium_status) {
            case PremuimStatus.PENDING:
                setTimeout(() => this.checkStatus(this.props.id), sleepTimeout);
                return;
            case PremuimStatus.WAITING_FOR_CAPTURE:
                setTimeout(() => this.checkStatus(this.props.id), sleepTimeout);
                return;
            case PremuimStatus.CANCELED:
                this.setState({
                    paymentProcess: false,
                });
                Dispatcher.dispatch({
                    name: MessageStoreAction.SHOW_MESSAGE,
                    payload: createComponent(AlertMessage, {
                        title: 'Платеж не прошел',
                        text: '',
                    }),
                });
                return;
        }
        this.props.premium = true;
        this.setState({
            paymentProcess: false,
        });
        return;
    };
    renderPromoteButton() {
        const promoteEvent = (e) => {
            e.stopPropagation();
            let period = PremiumPeriods.Week;
            if (this.state.modalActive) {
                return;
            }
            // eslint-disable-next-line @typescript-eslint/no-this-alias
            const cp = this;
            const accept = async () => {
                if (this.props) {
                    let res;
                    AbortSignal.timeout ??= function timeout(ms) {
                        const ctrl = new AbortController();
                        setTimeout(() => ctrl.abort(), ms);
                        return ctrl.signal;
                    };
                    const retryCount = 3;
                    const shortPull = useRetry(PremiumApi.add, retryCount);
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    for (const _ of Array(retryCount).keys()) {
                        res = await shortPull(this.props.id, period, AbortSignal.timeout(3000));
                        if (res) {
                            break;
                        }
                    }
                    cp.setState({
                        modalActive: undefined,
                    });
                    if (res === undefined) {
                        if (!MessageStore.getVisible()) {
                            Dispatcher.dispatch({
                                name: MessageStoreAction.SHOW_MESSAGE,
                                payload: createComponent(AlertMessage, {
                                    title: 'Что-то пошло не так',
                                    text: 'Кол-во попыток подключения превысило допустимое',
                                }),
                            });
                        }
                        return;
                    }
                    if (!ResponseStatusChecker.IsRedirectResponse(res)) {
                        return;
                    }
                    const url = res.body.redirect_url;
                    window.open(url, '_blank');
                    cp.setState({
                        paymentProcess: true,
                    });
                    cp.checkStatus(this.props.id);
                    // Navigate.navigateTo(url, {}, true);
                    // @FIX
                    //Navigate.navigateTo('/profile/orders');
                    //Navigate.navigateTo('/profile/products');
                }
            };
            const deny = () => this.setState({ modalActive: undefined });
            const chooseValue = (e) => {
                const select = e.currentTarget;
                period = Number(select.value);
            };
            const modal = createComponent(Modal, {
                onAccept: accept,
                onDeny: deny,
            }, createComponent(Select, {
                items: premiumPeriodsList,
                key: 'value',
                value: 'name',
                events: {
                    onchange: chooseValue,
                },
            }));
            this.setState({
                modalActive: modal,
            });
        };
        if (this.state.paymentProcess) {
            return createComponent(Button, {
                variant: (this.props.premium) ? 'secondary' : 'primary',
                style: 'width: 100%;',
                disabled: true,
            }, createComponent(Loader, {
                style: 'fill: white;',
            }));
        }
        return createComponent(Button, {
            variant: (this.props.premium) ? 'secondary' : 'primary',
            text: (this.props.premium) ? 'Продвижение активно' : 'Платное продвижение',
            style: 'width: 100%;',
            disabled: this.props.premium,
            onclick: promoteEvent,
        });
    }
    deleteProduct = async (e) => {
        e.stopPropagation();
        if (this.props) {
            let res;
            try {
                res = await ProductApi.delete(this.props.id);
            }
            catch (err) {
                console.error(err);
                return;
            }
            if (!ResponseStatusChecker.IsSuccessfulRequest(res)) {
                return;
            }
            if (this.props.removeCallback) {
                this.props.removeCallback(this.props.id);
            }
        }
    };
    deleteFavourite = async (e) => {
        if (!this.props.favouriteInfluence) {
            throw new Error('Favourite card props are undefined');
        }
        e.stopPropagation();
        if (this.props) {
            let res;
            try {
                res = await UserApi.removeFromFav(this.props.id);
            }
            catch (err) {
                console.error(err);
                return;
            }
            if (!ResponseStatusChecker.IsRedirectResponse(res)) {
                return;
            }
            this.props.favouriteInfluence(this.props.id);
        }
    };
    deleteFunction = (e) => {
        e.stopPropagation();
        switch (this.props?.variant) {
            case 'base':
                break;
            case 'favourite':
                this.deleteFavourite(e);
                break;
            case 'profile':
                this.deleteProduct(e);
                break;
        }
    };
    renderProfile() {
        const modal = [];
        if (this.state.modalActive) {
            modal.push(this.state.modalActive);
        }
        const variant = this.props.variant || 'profile';
        return createElement('card', {}, ...modal, createElement('button', {
            class: `card-profile${this.props.premium ? '--premium' : ''}`,
            onclick: this.navigateToProduct,
        }, createElement('div', { class: 'content-profile' }, (this.props.images) ?
            createComponent(Image, {
                class: 'image-profile',
                src: this.props.images[0].url,
            })
            :
                createElement('div', { class: 'image-profile' }), createComponent(Text, { text: this.props.price, type: 'price' }), createComponent(Text, { text: this.props.title, className: 'title-profile' }), createElement('div', { class: 'divider' }), (this.thisHaveBadges()) ?
            createElement('div', { class: 'badges-profile' }, ...this.renderBadges('badge-profile')) :
            createElement('div', { class: 'badges-profile' })), createElement('div', { class: 'content-buttons' }, (variant == 'profile') ?
            this.renderActiveButton()
            : createText(''), (variant == 'profile') ?
            this.renderPromoteButton()
            : createText(''), (variant == 'profile' || variant == 'favourite') ?
            createComponent(Button, {
                variant: 'accent',
                text: 'Удалить',
                className: 'content-buttons-delete',
                onclick: this.deleteFunction,
            }) : createText(''))));
    }
    render() {
        switch (this.props.variant) {
            case 'base':
                return this.renderBase();
            case 'profile':
                return this.renderProfile();
            case 'profile-saler':
                return this.renderProfile(); // renderProfile имеет внутренние проверки для случая чужой карточки
            case 'favourite':
                return this.renderProfile(); // renderProfile имеет внутренние проверки для случая карточки внутри изрбанного
            default:
                return this.renderBase();
        }
    }
}
