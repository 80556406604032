export var PremiumPeriods;
(function (PremiumPeriods) {
    PremiumPeriods[PremiumPeriods["Week"] = 1] = "Week";
    PremiumPeriods[PremiumPeriods["Month"] = 2] = "Month";
    PremiumPeriods[PremiumPeriods["ThreeMonth"] = 3] = "ThreeMonth";
    PremiumPeriods[PremiumPeriods["HalfYear"] = 4] = "HalfYear";
    PremiumPeriods[PremiumPeriods["Year"] = 5] = "Year";
})(PremiumPeriods || (PremiumPeriods = {}));
export const premiumPeriodsList = [
    {
        name: 'Неделя',
        value: PremiumPeriods.Week,
    },
    {
        name: 'Месяц',
        value: PremiumPeriods.Month,
    },
    {
        name: '3 Месяца',
        value: PremiumPeriods.ThreeMonth,
    },
    {
        name: 'Полгода',
        value: PremiumPeriods.HalfYear,
    },
    {
        name: '1 Год',
        value: PremiumPeriods.Year,
    },
];
export var PremuimStatus;
(function (PremuimStatus) {
    PremuimStatus[PremuimStatus["NOT_PREMIUM"] = 0] = "NOT_PREMIUM";
    PremuimStatus[PremuimStatus["PENDING"] = 1] = "PENDING";
    PremuimStatus[PremuimStatus["WAITING_FOR_CAPTURE"] = 2] = "WAITING_FOR_CAPTURE";
    PremuimStatus[PremuimStatus["SUCCEEDED"] = 3] = "SUCCEEDED";
    PremuimStatus[PremuimStatus["CANCELED"] = 4] = "CANCELED";
})(PremuimStatus || (PremuimStatus = {}));
