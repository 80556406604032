export class Navigate {
    callbacks = [];
    constructor() {
        window.addEventListener('popstate', () => {
            this.callbacks.forEach((callback) => {
                callback();
            });
        });
    }
    addCallback(newCallback) {
        this.callbacks.push(newCallback);
    }
    removeCallback(oldCallback) {
        this.callbacks = this.callbacks.filter(callback => callback !== oldCallback);
    }
    navigateTo(url, state = {}, out = false) {
        history.pushState(state, '', url);
        if (!out) {
            this.callbacks.forEach((callback) => {
                callback();
            });
        }
    }
}
export default new Navigate();
