import './orderFeed.scss';
import { Component } from '../baseComponents/snail/component';
import { createElement, createComponent } from '../baseComponents/snail/vdom/VirtualDOM';
import { OrderCard } from '../orderCard/orderCard';
import CartStore, { CartStoreAction } from '../../shared/store/cart';
import Dispatcher from '../../shared/services/store/Dispatcher';
import { Loader } from '../loader/Loader';
export class OrderFeed extends Component {
    state = {
        loading: true,
        error: false,
        orders: [],
    };
    componentDidMount() {
        CartStore.addStoreUpdater(() => {
            this.setState({
                loading: false,
                error: false,
                orders: CartStore.getGoods(),
            });
        });
        Dispatcher.dispatch({
            name: CartStoreAction.REFRESH,
        });
    }
    createOrders() {
        const objs = [];
        if (this.state.orders.length > 0) {
            this.state.orders.forEach((good) => objs.push(createComponent(OrderCard, good)));
        }
        return objs;
    }
    render() {
        return createElement('div', { class: 'order-feed' }, (this.state.loading) ?
            createComponent(Loader, {}) :
            createElement('div', { class: 'order-feed-content' }, ...this.createOrders()));
    }
}
