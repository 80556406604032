import { Store } from '../services/store/Store';
import { createText } from '../../components/baseComponents/snail/vdom/VirtualDOM';
import Navigate from '../services/router/Navigate';
import Dispatcher from '../services/store/Dispatcher';
export var MessageStoreAction;
(function (MessageStoreAction) {
    MessageStoreAction["SHOW_MESSAGE"] = "SHOW_MESSAGE";
    MessageStoreAction["HIDE_MESSAGE"] = "HIDE_MESSAGE";
})(MessageStoreAction || (MessageStoreAction = {}));
const initMessageStoreState = {
    content: createText(''),
    visible: false,
};
export class MessageStore extends Store {
    constructor() {
        super(initMessageStoreState);
        Navigate.addCallback(this.remove);
    }
    remove = () => {
        Dispatcher.dispatch({ name: MessageStoreAction.HIDE_MESSAGE });
    };
    showContent(newNode) {
        if (!this.state.visible) {
            this.state.content = newNode;
            this.state.visible = true;
        }
    }
    hideContent() {
        this.state.content = createText('');
        this.state.visible = false;
    }
    getVisible() {
        return this.state.visible;
    }
    getMessage() {
        return this.state.content;
    }
    addActions() {
        this.addAction({
            name: MessageStoreAction.SHOW_MESSAGE,
            operation: ({ payload }) => { this.showContent(payload); },
        });
        this.addAction({
            name: MessageStoreAction.HIDE_MESSAGE,
            operation: () => { this.hideContent(); },
        });
    }
}
export default new MessageStore();
