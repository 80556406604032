import { Ajax } from '../services/ajax';
export function getResourceUrl(resource) {
    if (typeof resource === 'string') {
        try {
            const adress = new URL(resource);
            return adress.toString();
        }
        catch (err) {
            return Ajax.getInstance().ADRESS_BACKEND + resource;
        }
    }
    try {
        const adress = new URL(resource.url);
        return adress.toString();
    }
    catch (err) {
        resource.url = Ajax.getInstance().ADRESS_BACKEND + resource.url;
        return resource;
    }
}
