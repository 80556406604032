import './button.scss';
import { Component } from '../snail/component';
import { createElement, createComponent, createText } from '../snail/vdom/VirtualDOM';
import { Text } from '../text/Text';
import { Svg } from '../svg/Svg';
import { Image } from '../image/image';
export class Button extends Component {
    render() {
        if (!this.children) {
            throw new Error('Button settings are undefined');
        }
        const { variant, subvariant, text, textvariant, leftIcon, rightIcon, className, ...buttonProps } = this.props;
        return createElement('button', {
            ...buttonProps,
            class: (className ? className : '') + ' button-' + (variant || 'base') + ' ' + (subvariant || ''),
        }, ...this.children, (leftIcon) ?
            createComponent(Svg, { ...leftIcon, id: 'left-icon' })
            : createText(''), (text !== undefined) ?
            createComponent(Text, {
                variant: textvariant || 'regular',
                text: text,
                style: 'text-align: center; text-wrap: nowrap;',
            })
            : createText(''), (rightIcon) ?
            createComponent(Svg, { ...rightIcon, id: 'right-icon' })
            : createText(''));
    }
}
export class ButtonImage extends Component {
    render() {
        const { src, variant, subvariant, srcVariant, ...buttonProps } = this.props;
        let { width, height, } = this.props;
        if (!width) {
            width = 60;
        }
        if (!height) {
            height = 60;
        }
        return createElement('button', {
            ...buttonProps,
            class: 'button-' + (variant || 'base') + ' ' + (subvariant || ''),
        }, createComponent(Image, {
            src: src,
            variant: srcVariant,
            width: width,
            height: height,
        }), ...this.children);
    }
}
