import './select.scss';
import { Component } from '../snail/component';
import { createComponent, createElement } from '../snail/vdom/VirtualDOM';
import { Option } from '../option/option';
export class Select extends Component {
    createOptions = () => {
        const { key, value, select } = this.props;
        return this.props.items.map((item) => {
            return createComponent(Option, {
                key: item[key],
                value: item[value],
                selected: item[key] == select ? true : false,
            });
        });
    };
    render() {
        let props = {};
        if (this.props.events) {
            props = {
                ...this.props.events,
            };
        }
        return createElement('select', props, ...this.createOptions());
    }
}
