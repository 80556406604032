import { cookieParser, deleteCookie } from '../utils/cookie';
import jwtDecode from '../utils/jwt-decode';
import { ResponseStatus, ResponseStatusChecker } from '../constants/response';
import { Store } from '../services/store/Store';
import { UserApi } from '../api/user';
export var UserStoreAction;
(function (UserStoreAction) {
    UserStoreAction["UPDATE"] = "USER_STORE_UPDATE";
    UserStoreAction["REFRESH"] = "USER_STORE_REFRESH";
    UserStoreAction["LOGOUT"] = "USER_STORE_LOGOUT";
    UserStoreAction["LOGIN"] = "USER_STORE_LOGIN";
})(UserStoreAction || (UserStoreAction = {}));
class UserStore extends Store {
    constructor() {
        const initState = {
            fields: null,
            accessToken: null,
        };
        super(initState);
        this.init();
    }
    update = (data) => {
        if (data) {
            this.state.fields = {
                ...this.state.fields,
                ...data,
            };
        }
    };
    logout = () => {
        deleteCookie('access_token');
        this.state.fields = null;
        this.state.accessToken = null;
    };
    login = async () => {
        const accessToken = cookieParser(document.cookie)?.access_token;
        if (accessToken) {
            const id = jwtDecode(accessToken).userID;
            if (id) {
                const res = await UserApi.getProfile(id);
                switch (res.status) {
                    case ResponseStatus.RESPONSE_SUCCESSFUL:
                        this.update(res.body);
                        break;
                    case ResponseStatus.INTERNAL_SERVER:
                        deleteCookie('access_token');
                        break;
                }
            }
        }
    };
    refresh = async () => {
        if (!this.state.fields?.id) {
            throw new Error('UserStore: id is undefined or null');
        }
        let res;
        try {
            res = await UserApi.getProfile(this.state.fields.id);
        }
        catch (err) {
            console.error(err);
        }
        if (!ResponseStatusChecker.IsSuccessfulRequest(res)) {
            console.error(new Error(res.body.error));
        }
        this.state.fields = res.body;
    };
    getFields = () => this.state.fields;
    isAuth() {
        return this.state.fields !== null ? true : false;
    }
    isSameUser = (id) => this.state.fields?.id === id;
    addActions() {
        this.addAction({
            name: UserStoreAction.UPDATE,
            operation: ({ payload }) => this.update(payload),
        });
        this.addAction({
            name: UserStoreAction.REFRESH,
            operation: () => this.refresh(),
        });
        this.addAction({
            name: UserStoreAction.LOGOUT,
            operation: () => this.logout(),
        });
        this.addAction({
            name: UserStoreAction.LOGIN,
            operation: () => this.login(),
        });
    }
}
export default new UserStore();
