export var ResponseStatus;
(function (ResponseStatus) {
    /**
     * RESPONSE_SUCCESSFUL - используется когда ответ успешный
     */
    ResponseStatus[ResponseStatus["RESPONSE_SUCCESSFUL"] = 2000] = "RESPONSE_SUCCESSFUL";
    /**
     * REDIRECT_AFTER_SUCCESSFUL - используется когда ответ успешный и нужно перенаправить на другой ресурс
     */
    ResponseStatus[ResponseStatus["REDIRECT_AFTER_SUCCESSFUL"] = 3003] = "REDIRECT_AFTER_SUCCESSFUL";
    /**
     * BAD_FORMAT_REQUEST - используется когда был отправлен неккоректный запрос на сервер.
     * Содержимое этой ошибки нужно только для разработчиков. Не показывать пользователям!
     */
    ResponseStatus[ResponseStatus["BAD_FORMAT_REQUEST"] = 4000] = "BAD_FORMAT_REQUEST";
    /**
     * BAD_CONTENT_REQUEST - используется когда был отправлен неккоректный запрос на сервер, но проблема в данных,
     * введенных клиентом. Эти ошибки нужно показывать пользователям.
     */
    ResponseStatus[ResponseStatus["BAD_CONTENT_REQUEST"] = 4400] = "BAD_CONTENT_REQUEST";
    /**
     * INTERNAL_SERVER - используется когда произошла ошибка на сервере.
     */
    ResponseStatus[ResponseStatus["INTERNAL_SERVER"] = 5000] = "INTERNAL_SERVER";
})(ResponseStatus || (ResponseStatus = {}));
export var ResponseMessage;
(function (ResponseMessage) {
    /**
 * USER_MESSAGE - используется как сообщение для пользователя при ошибке разработчика
 */
    ResponseMessage["USER_MESSAGE"] = "\u041F\u0440\u043E\u0438\u0437\u043E\u0448\u043B\u0430 \u0432\u043D\u0443\u0442\u0440\u0435\u043D\u043D\u044F\u044F \u043E\u0448\u0438\u0431\u043A\u0430";
    /**
     * SERVER_MESSAGE - используется как сообщение при ошибке на сервере
     */
    ResponseMessage["SERVER_MESSAGE"] = "\u041E\u0448\u0438\u0431\u043A\u0430 \u043D\u0430 \u0441\u0435\u0440\u0432\u0435\u0440\u0435";
})(ResponseMessage || (ResponseMessage = {}));
export class ResponseStatusChecker {
    /**
 * @summary определяет является ли запрос успешным
 * @param {Object} body body ответа с status полем
 * @return {boolean}
 */
    static IsSuccessfulRequest(body) {
        return body.status === ResponseStatus.RESPONSE_SUCCESSFUL;
    }
    /**
     * @summary определяет является ли ответ rediret-ом
     * @param {Object} body body ответа с status полем
     * @return {boolean}
     */
    static IsRedirectResponse(body) {
        return body.status === ResponseStatus.REDIRECT_AFTER_SUCCESSFUL;
    }
    /**
     * @summary определяет произошла ли ошибка из-за неккоретного формата запроса
     * @param {Object} body body ответа с status полем
     * @return {boolean}
     */
    static IsBadFormatRequest(body) {
        return body?.status === ResponseStatus.BAD_FORMAT_REQUEST;
    }
    /**
     * @summary определяет является ли ошибка такой, которую можно показать пользователю
     * @param {Object} body body ответа с status полем
     * @return {boolean}
     */
    static IsUserError(body) {
        return body?.status === ResponseStatus.BAD_CONTENT_REQUEST;
    }
    /**
     * @summary определяет произошла ли ошибка на сервере
     * @param {Object} body body ответа с status полем
     * @return {boolean}
     */
    static IsInternalServerError(body) {
        return body?.status === ResponseStatus.INTERNAL_SERVER;
    }
}
