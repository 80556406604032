import { Component } from '../../components/baseComponents/snail/component';
import { createComponent, createElement } from '../../components/baseComponents/snail/vdom/VirtualDOM';
import { Feed } from '../../components/feed/feed';
import { Header } from '../../components/header/header';
export class MainPage extends Component {
    constructor() {
        super();
        document.title = 'GoodsGalaxy | Главная';
    }
    feedComp;
    componentWillUnmount() {
        (this.feedComp?.instance).unmount();
    }
    render() {
        this.feedComp = createComponent(Feed, {});
        return createElement('mainpage', {}, createComponent(Header, {}), this.feedComp);
    }
}
