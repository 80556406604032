import './checkNote.scss';
import { Component } from '../../../../components/baseComponents/snail/component';
import { createElement, createComponent } from '../../../../components/baseComponents/snail/vdom/VirtualDOM';
import { Text } from '../../../../components/baseComponents';
export class CheckNote extends Component {
    getTextVariant = () => this.props.variant == 'header' ? 'subheader' : 'regular';
    render() {
        return createElement('div', { class: 'check-note' }, createComponent(Text, {
            tag: 'div',
            variant: this.getTextVariant(),
            text: this.props.name,
        }), createComponent(Text, {
            tag: 'div',
            variant: this.getTextVariant(),
            text: this.props.price,
            type: 'price',
        }));
    }
}
